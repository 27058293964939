import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import { selectProjectById, selectProjectMapping } from '../../../../state/projects/selectors'
import styles from '../../../../styles/EditProject.module.scss'
import CancelIcon from '../../../../../../assets/svg/x-instr.svg'
import Dialog from '../../Common/Dialog'
import getUsers from '../../../../api/users'

function ProjectAuthorizationPanel({ updateProject, project, currentUser, texts }) {
  const [selected, setSelected] = useState(project.authorized_user_ids || [])
  const [searching, setSearching] = useState(false)
  const containerInputRef = React.useRef(null)
  const lastSelectedRef = React.useRef(null)
  const [userToRemove, setUserToRemove] = useState(null)

  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      // fetch users from api
      const response = await getUsers(1000)

      // FILTER USERS: exclude current user
      // and include only Admin, Superuser “Pilot” roles
      const responseFiltered = response
        ? response.filter(
            item =>
              item.id !== currentUser.id &&
              item.roles.some(
                role => role.slug === 'super-admin' || role.slug === 'super-user' || role.slug === 'pilot'
              )
          )
        : []

      console.log(responseFiltered)

      setUsers(responseFiltered)
    }
    fetchData()
  }, [])

  const addSelectedUser = id => {
    setSelected([...selected, id])
    updateProject(project.id, { authorized_user_ids: [...selected, id] })
  }

  const handleOnSearch = string => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearching(() => string !== lastSelectedRef?.current?.email)
  }

  const handleOnSelect = item => {
    // the item selected
    addSelectedUser(item.id)
    setSearching(false)
    //  add extra action to clear text input in some cose
    // like when enter key is pressed for selection
    lastSelectedRef.current = item
    const input = containerInputRef.current.querySelector('input')
    input.value = ''
  }

  const inputSearchString = item => {
    return item && searching ? item : ''
  }

  const formatResult = item => {
    return (
      <>
        <span
          style={{
            display: 'block',
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          }}
        >
          {item.email}
        </span>
      </>
    )
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const removeUser = user => {
    setUserToRemove(user)
    setOpenDeleteDialog(true)
  }

  const handleClickDialogDelete = () => {
    setConfirmDelete(true)
  }
  const handleClickConfirmDelete = () => {
    const updatedAuthorizedUsers = selected.filter(item => item !== userToRemove.id)
    setSelected(updatedAuthorizedUsers)
    // api call to remove user
    updateProject(project.id, { authorized_user_ids: updatedAuthorizedUsers })
    setOpenDeleteDialog(false)
  }

  const DeleteActionText = 'Remove'

  return (
    <div className={styles.ProjectAuthorizationPanel}>
      <div className={styles.ProjectItemsList_header}>
        <div className={styles.ProjectItemsList_header_icon}>
          <PersonOutlineIcon />
        </div>
        <div className={styles.ProjectItemsList_header_title}>{texts.authorize_users}</div>
      </div>
      <div ref={containerInputRef}>
        <div style={{ marginLeft: 7 }}>{texts.search_users}</div>
        <ReactSearchAutocomplete
          items={users.filter(item => !selected.includes(item.id))}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          showItemsOnFocus
          inputSearchString={inputSearchString}
          showClear={false}
          formatResult={formatResult}
          styling={{
            height: '40px',
          }}
        />
        {selected.length > 0 && (
          <div className={styles.ProjectAuthorizationPanel_users}>
            <div style={{ marginLeft: 7 }}>{texts.authorized_users}</div>
            <div className={styles.ProjectAuthorizationPanel_users_list}>
              {selected.map((id, index) => {
                const user = users.find(item => item.id === id)
                return (
                  <div key={index} className={styles.ProjectAuthorizationPanel_user}>
                    <div className={styles.ProjectAuthorizationPanel_user_name}>{user?.email}</div>
                    <div
                      onClick={() => removeUser(user)}
                      className={styles.ProjectAuthorizationPanel_user_remove}
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => {}}
                    >
                      <CancelIcon />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={confirmDelete ? handleClickConfirmDelete : handleClickDialogDelete}
        title={`${DeleteActionText}  ${userToRemove?.email}`}
        text={
          confirmDelete
            ? `${texts.authorize_users_delete_confirm_text} ${userToRemove?.email}?`
            : `${texts.authorize_users_delete_confirm_text} ${userToRemove?.email}?`
        }
        textColor={confirmDelete && 'red'}
        confirmButtonText={DeleteActionText}
        closeOnConfirm={false}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    mapping: selectProjectMapping(state, state.renaultProjectMode.editProject.id),
    currentUser: state.authentication.user,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectAuthorizationPanel)
