import React, { useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../../../../../../store/actions'
import * as renaultProjectModeActionCreators from '../../../../../state/actions'
import ProjectItemName from './ProjectItemName'
import EditIcon from '../../../../../../../assets/svg/edit-scoring-icon.svg'
import DotsIcon from '../../../../../../../assets/svg/dots.svg'
import ConfigurationIcon from '../../../../../../../assets/svg/document.svg'
import BenchmarkIcon from '../../../../../../../assets/svg/clone.svg'
import ProjectItemMenu from './ProjectItemMenu'
import styles from '../../../../../styles/EditProject.module.scss'
import ProjectDueDate from './ProjectDueDate'
import { selectProjectMappingByConfigurationCategory } from '../../../../../state/projects/selectors'
import { selectProductsByMapping } from '../../../../../state/products/selectors'
import IconLock from '../../../../../../../assets/svg/lock_product_menu_black.svg'

function ProjectItem({ projectItem, projectItemProducts, texts, index }) {
  const snakeCaseSlug = _.replace(projectItem.type, '-', '_')
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const isMenuOpen = Boolean(menuAnchorEl)

  const benchmarks = _.filter(projectItemProducts, product => !product?.is_configuration)
  const configurations = _.filter(projectItemProducts, product => product?.is_configuration)
  const isLocked = !!projectItem.is_locked
  return (
    <>
      <div
        className={styles.ProjectItem}
        style={{ backgroundImage: `url(/renault-project-mode/${snakeCaseSlug}_bg.png)` }}
      >
        <div className={styles.ProjectItem_type}>
          {texts[`configuration_category_${snakeCaseSlug}_title`]} {index}
        </div>

        <div className={styles.ProjectItem_menu}>
          {isLocked && (
            <div className={styles.ProjectItem_menu_lock}>
              <IconLock />
            </div>
          )}
          <button type="button" className={styles.ProjectItem_button} onClick={e => setMenuAnchorEl(e.currentTarget)}>
            <DotsIcon width="16px" className="theme_svg" />
          </button>
        </div>

        <ProjectItemName projectItem={projectItem} />

        {projectItemProducts && projectItemProducts.length > 0 && (
          <div className={styles.ProjectItem_products}>
            {configurations && configurations.length > 0 && (
              <div className={styles.ProjectItem_products_row}>
                <div className={styles.ProjectItem_products_row_icon}>
                  <ConfigurationIcon />
                </div>
                <div className={styles.ProjectItem_products_row_text}>
                  <span className={styles.ProjectItem_products_row_text_number}>{configurations.length}</span>{' '}
                  {texts.configurations}
                </div>
              </div>
            )}

            {benchmarks && benchmarks.length > 0 && (
              <div className={styles.ProjectItem_products_row}>
                <div className={styles.ProjectItem_products_row_icon}>
                  <BenchmarkIcon />
                </div>
                <div className={styles.ProjectItem_products_row_text}>
                  <span className={styles.ProjectItem_products_row_text_number}>{benchmarks.length}</span>{' '}
                  {texts.benchmarks}
                </div>
              </div>
            )}
          </div>
        )}

        <ProjectDueDate projectItem={projectItem} />

        <div className={styles.ProjectItem_actions}>
          <a
            href={`/project-mode/comparison-board?project_id=${projectItem.project_id}&configuration_category_id=${projectItem.id}`}
            className={styles.ProjectItem_button}
          >
            <EditIcon width="16px" className="theme_svg" />
          </a>
        </div>
      </div>

      {isMenuOpen && (
        <ProjectItemMenu
          anchorEl={menuAnchorEl}
          configurationCategory={projectItem}
          onClose={() => setMenuAnchorEl(null)}
        />
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const projectItemMapping = selectProjectMappingByConfigurationCategory(
    state,
    ownProps.projectItem.project_id,
    ownProps.projectItem.id
  )

  return {
    texts: state.texts.values,
    projectItemProducts: selectProductsByMapping(state, projectItemMapping),
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(ProjectItem)
