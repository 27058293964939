import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import { selectProjectById, selectProjectMapping } from '../../../state/projects/selectors'
import Config from '../../../config.json'
import ProjectItemsList from './ProjectItemsList'
import styles from '../../../styles/EditProject.module.scss'
import ProjectProductsList from './ProjectProductsList'
import ProjectAuthorizationPanel from './ProjectAuthorization'

const classNames = require('classnames')

function Body({ currentUser, project }) {
  const showAuthorizationPanel =
    currentUser?.id === project?.author_id || currentUser.roles.find(_r => _r.slug === 'super-admin')

  return (
    <div
      id="editProjectPageScroller"
      className={classNames({
        [styles.EditProjectPage_body]: true,
        [styles.EditProjectPage_body_action_panel]: showAuthorizationPanel,
      })}
    >
      {showAuthorizationPanel && (
        <div
          className={classNames({
            [styles.EditProjectPage_body_action_panel_left]: showAuthorizationPanel,
          })}
        >
          <ProjectAuthorizationPanel />
        </div>
      )}
      <div
        className={classNames({
          [styles.EditProjectPage_body_action_panel_right]: showAuthorizationPanel,
        })}
      >
        {Config.configuration_categories.map(type => (
          <ProjectItemsList key={type} type={type} />
        ))}
        <ProjectProductsList />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    project: selectProjectById(state, state.renaultProjectMode.editProject.id),
    mapping: selectProjectMapping(state, state.renaultProjectMode.editProject.id),
    currentUser: state.authentication.user,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Body)
